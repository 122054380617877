import React from "react";

import SectionLayout from "./SectionLayout";

const isReactElement = sectionLayout =>
  Object.keys(sectionLayout).indexOf("$$typeof") >= 0;

const hasPageTitle = sectionLayouts =>
  sectionLayouts[0].sectionTitle === "Page Title";

const hasPageTitleBackground = sectionLayoutObj => {
  const { sectionLayout } = sectionLayoutObj;
  const { props } = sectionLayout.components[0].component;
  const { bgImage, bgColor } = props;
  return bgImage || bgColor;
};

const getSectionMargin = (sectionLayouts, index) => {
  const hasPageTitleWithBackground =
    !isReactElement(sectionLayouts[index]) &&
    hasPageTitle(sectionLayouts) &&
    hasPageTitleBackground(sectionLayouts[0]);
  return index === 0 && hasPageTitleWithBackground;
};

const SectionLayouts = ({ sectionLayouts }) => (
  <div>
    {sectionLayouts.map((sectionLayout, index) => (
      <SectionLayout
        isPageTitle={getSectionMargin(sectionLayouts, index)}
        key={sectionLayout.sectionTitle}
        sectionLayoutConfiguration={sectionLayout}
        isReactElement={isReactElement(sectionLayout)}
      />
    ))}
  </div>
);

export default SectionLayouts;
